import React, { FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import TextField from '@material-ui/core/TextField';
// import ToggleFullscreenButton from '../ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
// import Menu from './Menu/Menu';

import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
// import { Typography } from '@material-ui/core';
// import healthbridgeLogo from './healthbridge-logo.png';
import * as FirestoreService from '../../services/firestore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      minHeight: '35vh',
    },
    toolbar: {
      position: 'relative',
      height: '100%',
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexFlow: 'wrap',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'space-around',
      marginTop: '10px',
      marginBottom: '10px',
      height: '90%',
      width: '100%',
      textAlign: 'center',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    participants: {
      display: 'flex',
    },
    hbbtn: {
      display: 'flex',
      backgroundColor: '#195c2a',
      marginRight: '1em',
    },
    loadingSpinner: {
      display: 'flex',
    },
    displayName: {
      marginLeft: '2.2em',
      minWidth: '200px',
      fontWeight: 600,
    },
    [theme.breakpoints.up('md')]: {
      container: {
        width: '30vw',
        height: '35vh',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { BPN, TPN, URLRoomName } = useParams();
  const { user, getToken, isFetching, isDoctor } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const roomState = useRoomState();

  const [name] = useState<string>(user?.displayName || '');
  const [bpn, setBPN] = useState<string>('');
  const [tpn, setTPN] = useState<string>('');
  const [roomName, setRoomName] = useState<string>('');
  const [twilioRoomName, setTwilioRoomName] = useState<string>('');
  const [participants, setParticipants] = useState([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (BPN) {
      setBPN(BPN);
    }
    if (TPN) {
      setTPN(TPN);
    }
    if (URLRoomName && BPN && TPN) {
      setRoomName(`${URLRoomName}`);
      setTwilioRoomName(`${BPN}_${TPN}_${URLRoomName}`);
    }
  }, [BPN, TPN, URLRoomName]);

  useEffect(() => {
    if (bpn && tpn && roomName) {
      const unsubscribe = FirestoreService.streamRoomParticiapnts(bpn, tpn, roomName, {
        next: (querySnapshot: any) => {
          const updatedParticipants = querySnapshot.docs.map((docSnapshot: any) => docSnapshot.data());
          setParticipants(updatedParticipants);
          // console.log(updatedParticipants);
        },
        error: (err: any) => setError(err),
      });
      return unsubscribe;
    }
  }, [bpn, tpn, roomName, setParticipants]);

  if (error) {
    console.info(`Error: ${error}`);
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const r = isDoctor || window.confirm(
      "By clicking 'OK' you give your consent that you may be examined via Video Consult and be billed for the consultation."
    );
    if (r === true) {
      const token = await getToken(name, twilioRoomName, '', bpn, tpn);
      await connect(token);
    }
    // window.history.replaceState(null, '', window.encodeURI(`/${bpn}/${tpn}/${roomName}`));
  };

  const participantNames =
    participants.length > 0 ? (
      participants.map((participant: any) => (
        <h3 className={classes.participants}>{participant.displayName} has joined the consultation.</h3>
      ))
    ) : (
      isDoctor ? (
        <h3 className={classes.participants}>The patient has not joined the consult yet.</h3>
      ) : (
        <h3 className={classes.participants}>Please join the consult and wait for the other party to join.</h3>
      )
    );

  return roomState === 'disconnected' ? (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        <form className={classes.form} onSubmit={handleSubmit}>
          {!user?.displayName && <h3>Plese logout and choose a display name.</h3>}
          <Button
            className={classes.hbbtn}
            type="submit"
            color="inherit"
            variant="contained"
            disabled={isConnecting || !name || !roomName || isFetching}
          >
            Join Consultation
          </Button>
          {participantNames}
          {isConnecting || isFetching ? <div>Joining room...</div> : <div>Ready to join.</div>}
        </form>
      </Toolbar>
    </AppBar>
  ) : null;
}
