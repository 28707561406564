import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// import Fab from '@material-ui/core/Fab';
import MicOutlined from '@material-ui/icons/MicOutlined';
import MicOffOutlined from '@material-ui/icons/MicOffOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      backgroundColor: 'transparent',
      borderWidth: '2px',
      borderRadius: '28px',
      height: '56px',
      width: '56px',
      padding: '0px',
    },
  })
);

export default function ToggleAudioButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  return (
    <Tooltip
      title={isAudioEnabled ? 'Mute Audio' : 'Unmute Audio'}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Button
        variant="outlined"
        color="inherit"
        className={classes.fab}
        onClick={toggleAudioEnabled}
        disabled={props.disabled}
        data-cy-audio-toggle
      >
        {isAudioEnabled ? <MicOutlined /> : <MicOffOutlined />}
      </Button>
    </Tooltip>
  );
}
