import React, { ChangeEvent, useState, FormEvent } from 'react';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
// import { ReactComponent as TwilioLogo } from './twilio-logo.svg';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// import videoLogo from './video-logo.png';
import healthbridgeLogo from './HB-LogoNew-NoPayoff.png';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    background: '#0D122B',
  },
  videoLogo: {
    width: '150px',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2em',
    marginTop: '4em',
    background: 'white',
    color: 'black',
    width: '20em',
    textAlign: 'center',
  },
  button: {
    color: 'black',
    background: 'white',
    margin: '0.8em 0 0.7em',
    textTransform: 'none',
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function LoginPage() {
  const classes = useStyles();
  const { signIn, isAuthReady } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [passcode, setPasscode] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [authError, setAuthError] = useState<Error | null>(null);

  // const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  const login = async () => {
    try {
      setAuthError(null);
      await signIn?.(passcode, displayName);
      history.replace(location?.state?.from);
    } catch (err) {
      setAuthError(err);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await login();
  };

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  if (!isAuthReady) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container justify="center" alignItems="flex-start" className={classes.container}>
        <Paper className={classes.paper} elevation={6}>
          <h2>Telehealth</h2>
          Welcome to the simplest video consultation.
          <br />
          <br />
          <span>
            <strong>Note:</strong> Please ensure that your camera &amp; microphone are enabled.
          </span>
          <br />
          <br />
          <strong>Powered by</strong>
          <img className={classes.videoLogo} src={healthbridgeLogo} alt="Healthbridge Logo"></img>
          {process.env.REACT_APP_SET_AUTH === 'firebase' && (
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" direction="column">
                <TextField
                  id="input-display-name"
                  label="Display Name"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setDisplayName(e.target.value)}
                  type="text"
                />
                <div>
                  {authError && (
                    <Typography variant="caption" className={classes.errorMessage}>
                      <ErrorOutlineIcon />
                      {authError.message}
                    </Typography>
                  )}
                </div>
                <Button variant="contained" className={classes.button} onClick={login} disabled={!displayName.length}>
                  Continue
                </Button>
              </Grid>
            </form>
          )}
          {process.env.REACT_APP_SET_AUTH === 'passcode' && (
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" direction="column">
                <TextField
                  id="input-passcode"
                  label="Passcode"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
                  type="password"
                />
                <div>
                  {authError && (
                    <Typography variant="caption" className={classes.errorMessage}>
                      <ErrorOutlineIcon />
                      {authError.message}
                    </Typography>
                  )}
                </div>
                <Button variant="contained" className={classes.button} type="submit" disabled={!passcode.length}>
                  Submit
                </Button>
              </Grid>
            </form>
          )}
        </Paper>
      </Grid>
    </ThemeProvider>
  );
}
