import React from 'react';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
// import Controls from '../Controls/Controls';
import styled from '@material-ui/core/styles/styled';

const Video = styled('div')(({ theme }) => ({
  height: '100%',
  bottom: '0px',
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
}));

export default function LocalVideoPreview() {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(track => track.name === 'camera') as LocalVideoTrack;

  return videoTrack ? (
    <Video>
      <VideoTrack track={videoTrack} isLocal />
    </Video>
  ) : (
    <Video></Video>
  );
}
