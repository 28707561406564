import { useCallback, useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import * as FirestoreService from '../../services/firestore';

export default function useFirebaseAuth() {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [isDoctor, setIsDoctor] = useState(false);

  const getToken = useCallback(
    async (identity: string, roomName: string, bpn: string, tpn: string) => {
      const headers = new window.Headers();

      const idToken = await user!.getIdToken();
      headers.set('Authorization', idToken);

      const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
      const params = new window.URLSearchParams({ identity, roomName, bpn, tpn });

      return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());
    },
    [user]
  );

  useEffect(() => {
    FirestoreService.auth().onAuthStateChanged(user => {
      setUser(user);
      setIsAuthReady(true);
    });
  }, []);

  const signIn = useCallback(async (passcode: string, displayName: string) => {
    // const provider = new firebase.auth.GoogleAuthProvider();
    // provider.addScope('https://www.googleapis.com/auth/plus.login');
    // console.log(displayName);
    const user = await FirestoreService.authenticateAnonymously();
    const user$ = user.user;
    await user$?.updateProfile({ displayName: displayName });
    setUser(user$);
    return Promise.resolve();
  }, []);

  const signOut = useCallback(() => {
    return FirestoreService.singOut().then(() => {
      setUser(null);
    return Promise.resolve();
    });
  }, []);

  const setDoctor = useCallback((_isDoctor: boolean) => {
    setIsDoctor(_isDoctor);
  }, [])

  return { user, signIn, signOut, isAuthReady, getToken, setDoctor, isDoctor };
}
