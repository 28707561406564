import 'firebase/firestore';
import 'firebase/auth';
import firebase from 'firebase';

const firebaseConfig = {
  projectId: 'healthbridge-videochat',
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export const authenticateAnonymously = (): Promise<firebase.auth.UserCredential> => {
  return firebase.auth().signInAnonymously();
};

export const singOut = (): Promise<void> => {
  return firebase.auth().signOut();
};

export const auth = (): firebase.auth.Auth => {
  return firebase.auth();
};

export const streamRoomParticiapnts = (bpn: any, tpn: any, room: any, observer: any) => {
  // console.log(`doctors/${bpn}_${tpn}/rooms/${room}/participants`);
  return db
    ?.collection('doctors')
    .doc(`${bpn}_${tpn}`)
    .collection('rooms')
    .doc(`${room}`)
    .collection('participants')
    .where('status', '==', 'participant-connected')
    .onSnapshot(observer);
};

export const streamRoomMessages = (bpn: any, tpn: any, room: any, observer: any) => {
  // console.log(`doctors/${bpn}_${tpn}/rooms/${room}/participants`);
  return db
    ?.collection('doctors')
    .doc(`${bpn}_${tpn}`)
    .collection('rooms')
    .doc(`${room}`)
    .collection('messages')
    .orderBy('createdAt')
    .onSnapshot(observer);
};

export const addRoomMessage = async (bpn: any, tpn: any, room: any, displayName: any, message: any, id: any) => {
  // console.log(`doctors/${bpn}_${tpn}/rooms/${room}/participants`);
  return db
    ?.collection('doctors')
    .doc(`${bpn}_${tpn}`)
    .collection('rooms')
    .doc(`${room}`)
    .collection('messages')
    .doc(id)
    .set({ createdAt: new Date(), displayName: displayName, message: message });
};

export const getDocId = async (bpn: any, tpn: any, room: any) => {
  const docRef = db
    ?.collection('doctors')
    .doc(`${bpn}_${tpn}`)
    .collection('rooms')
    .doc(`${room}`)
    .collection('messages')
    .doc();
  return docRef.id;
};

export const getRoomFromShortURL = async (shortURL: any) => {
  const roomsRef = await db
    ?.collectionGroup('rooms')
    .where('shortURL', '==', shortURL)
    .get();
  let expandedURL = '/';
  if (roomsRef.docs.length === 1) {
    const roomData = roomsRef.docs[0].data();
    expandedURL = `/${roomData.bpn}/${roomData.tpn}/${roomData.room}`;
  }
  return expandedURL;
};
