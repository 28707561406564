import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import * as FirestoreService from '../../services/firestore';
import { useAppState } from '../../state/index';

export default function RedirectToRoom() {
  const { shortURL, doctor } = useParams();
  const [expandedURL, setExpandedURL] = useState<string>('');
  const [sURL, setSURL] = useState<string>('');
  const { setDoctor } = useAppState();

  useEffect(() => {
    if (shortURL) {
      setSURL(shortURL);
    }
    if (doctor && doctor === 'd') {
      if (setDoctor) setDoctor(true);
    }
  }, [shortURL, doctor, setDoctor]);

  useEffect(() => {
    const fetchURL = async (shrtURL: any) => {
      setExpandedURL(await FirestoreService.getRoomFromShortURL(shrtURL));
    };
    if (sURL) {
      fetchURL(sURL);
    }
  }, [sURL]);

  return expandedURL ? <Redirect to={expandedURL} /> : null;
}
