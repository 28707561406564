import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import VideocamOutlined from '@material-ui/icons/VideocamOutlined';
import VideocamOffOutlined from '@material-ui/icons/VideocamOffOutlined';

import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      backgroundColor: 'transparent',
      borderWidth: '2px',
      borderRadius: '28px',
      height: '56px',
      width: '56px',
      padding: '0px',
    },
  })
);

export default function ToggleVideoButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();

  return (
    <Tooltip
      title={isVideoEnabled ? 'Mute Video' : 'Unmute Video'}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Button
        variant="outlined"
        color="inherit"
        className={classes.fab}
        onClick={toggleVideoEnabled}
        disabled={props.disabled}
      >
        {isVideoEnabled ? <VideocamOutlined /> : <VideocamOffOutlined />}
      </Button>
    </Tooltip>
  );
}
