import React, { useState, useEffect } from 'react';
import { styled, makeStyles, Theme, createStyles } from '@material-ui/core/styles';

// import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

import useRoomState from './hooks/useRoomState/useRoomState';
import { Widget, addResponseMessage, addUserMessage, isWidgetOpened } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';
import { useParams } from 'react-router-dom';
import { useAppState } from './state';
import * as FirestoreService from './services/firestore';
import './App.css';
import healthbridgeLogo from './healthbridge-logo.png';
import Menu from './components/MenuBar/Menu/Menu';
import Controls from './components/Controls/Controls';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100vh',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const displayedMessages: any = {};
let unreadMessages = 0;

export default function App() {
  const roomState = useRoomState();
  const { BPN, TPN, URLRoomName, doctor } = useParams();
  const { user, setDoctor } = useAppState();

  const [name] = useState<string>(user?.displayName || '');
  const [bpn, setBPN] = useState<string>('');
  const [tpn, setTPN] = useState<string>('');
  const [roomName, setRoomName] = useState<string>('');
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState<string>('');
  const [disconnected, setDisconnected] = useState<boolean>(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      topBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 10,
        position: 'absolute',
      },
      lobby: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '100%',
      },
      main: {
        width: '100%',
        height: '80%',
        alignContent: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
          padding: '30px',
          paddinRight: '20px',
          borderradius: '10px',
        },
        [theme.breakpoints.down('md')]: {
          maxHeight: disconnected ? '60vh' : '100%',
        },
      },
      menu: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'start',
        [theme.breakpoints.up('md')]: {
          padding: '20px',
          paddingLeft: '10px',
        },
        [theme.breakpoints.down('md')]: {
          padding: '10px',
          height: '40vh',
          minHeight: '40vh',
          width: '100%',
        },
      },
      hbLogo: {
        height: '40px',
        marginLeft: '1em',
        marginTop: '1em',
        zIndex: 10,
      },
    })
  );

  const classes = useStyles();

  const postLobbyEvent = (bpn: any, tpn: any, roomName: any, displayName: any) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://us-central1-healthbridge-videochat.cloudfunctions.net/app/postEvent');
    xhr.send(
      JSON.stringify({
        bpn: bpn,
        tpn: tpn,
        consultID: roomName,
        timestamp: new Date(),
        event: 'lobby-entered',
        participantName: displayName,
        participantID: null,
        roomID: null,
      })
    );
  };

  useEffect(() => {
    if (BPN) {
      setBPN(BPN);
    }
    if (TPN) {
      setTPN(TPN);
    }
    if (URLRoomName && BPN && TPN) {
      setRoomName(`${URLRoomName}`);
    }
    if (doctor && doctor === 'd') {
      if (setDoctor) setDoctor(true);
    }
  }, [BPN, TPN, URLRoomName, doctor, setDoctor]);

  useEffect(() => {
    setDisconnected(roomState === 'disconnected');
  }, [roomState]);

  useEffect(() => {
    if (bpn && tpn && roomName && name) {
      postLobbyEvent(bpn, tpn, roomName, name);
    }
  }, [bpn, tpn, roomName, name]);

  useEffect(() => {
    if (bpn && tpn && roomName) {
      const unsubscribe = FirestoreService.streamRoomMessages(bpn, tpn, roomName, {
        next: (querySnapshot: any) => {
          const updatedMessages = querySnapshot.docs.map((docSnapshot: any) => {
            const data = docSnapshot.data();
            return { id: docSnapshot.id, ...data };
          });
          setMessages(updatedMessages);
          // console.log(updatedParticipants);
        },
        error: (err: any) => setError(err),
      });
      return unsubscribe;
    }
  }, [bpn, tpn, roomName, setMessages]);

  if (error) {
    console.info(`Error: ${error}`);
  }

  const handleNewUserMessage = async (newMessage: any) => {
    const docId = await FirestoreService.getDocId(bpn, tpn, roomName);
    displayedMessages[docId] = true;
    await FirestoreService.addRoomMessage(bpn, tpn, roomName, name, newMessage, docId);
    // console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API
  };

  messages.forEach((message: any) => {
    if (isWidgetOpened()) {
      unreadMessages = 0;
    }
    if (!displayedMessages[message.id]) {
      // console.log(displayedMessages[message.id]);
      displayedMessages[message.id] = true;
      if (message.displayName === name) {
        addUserMessage(message.message);
      } else {
        if (!isWidgetOpened()) {
          unreadMessages++;
        }
        addResponseMessage(`${message.displayName}: ${message.message}`);
      }
    }
  });

  return (
    <div>
      <div className={classes.topBar}>
        <img className={classes.hbLogo} src={healthbridgeLogo} alt="Healthbridge Logo"></img>
        <Menu />
      </div>
      <Container>
        <div className={classes.main}>
          {roomState === 'disconnected' ? (
            <>
              <LocalVideoPreview />
              <Controls />
            </>
          ) : (
            <Room />
          )}
        </div>
        <div className={classes.menu}>
          <MenuBar />
        </div>
      </Container>
      <ReconnectingNotification />
      <Widget handleNewUserMessage={handleNewUserMessage} badge={unreadMessages} />
    </div>
  );
}
